import React,{useState,useEffect } from 'react';
import './community.css';
import { Link,useNavigate } from 'react-router-dom';
import { Button, Modal,Toast,Badge } from "react-bootstrap";
import http from "../../http"
import {GrView} from "react-icons/gr";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';

const ContactsHelp = () => {

  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const [jobPost, setJobPost] = useState([]);
  const [search, setSearch] = useState("");
  const [filterJobPost, setFilterJobPost] = useState([]);
  const image = process.env.REACT_APP_IMAGE_BASE_URL

  const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(15);
	const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
  const cookies = new Cookies();
  
  

    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/");
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = (id) => {
        setShow(true);
        setId(id)  
    };

    useEffect(()=>{
        let query = search.length > 0 ? `search/${search}` : `admin/all`;

        http
          .get(
            `/api/admin/contacts-help-list?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,
            {
              headers: {
                Authorization: "Bearer " + cookies.get("userAuthToken"),
              },
            }
          )
          .then((response) => {
            // setTotalRows(response.data.data.total);
            //console.log("all job category***", response.data.data)
            setFilterJobPost(response.data.data.data);
          });
        
    },[search]);

    // console.log(filterJobPost)

    // +++++++++++++++++++
    const fetchAllJobPost = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(
      `/api/admin/contacts-help-list?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      }
    );
        // console.log("all job list *************", response.data.data.data)
        setFilterJobPost(response.data.data.data)
		setJobPost(response.data.data.data);
		setTotalRows(response.data.data.total);
		setLoading(false);
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `search/${search}` : `list`;

		setLoading(true);
		const response = await http.get(
      `/api/admin/contacts-help-list?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,
      {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      }
    );
        // console.log(response.data);
        
		setJobPost(response.data.data.data);
        // console.log("+++",response.data.data.data);
        setFilterJobPost(response.data.data.data)
		setPerPage(perPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAllJobPost(1); // fetch page 1 of users
		
	}, []);

   
    



    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: "Type",
            selector: row => row.subject_type,
            sortable:true
        },
        {
            name: "Subject",
            selector: row => row.contact_subject.subject,
            sortable:true
        },
        {
            name: "Attachment",
            selector: row => (row.file) ? <img width={50} height={50} src={image+'/'+row.file} /> 
            : 
            <Badge bg="warning" text="dark">N/A</Badge>,
        },
        {
            name: "Status",
            selector: (row) =>
              row.status == "Pending" ? (
                <Badge bg="warning" text="dark">
                  {row.status}
                </Badge>
              ) : (
                <Badge bg="info" text="light">
                  {row.status}
                </Badge>
              ),
            sortable: true,
        },
        {
            name: "Action",
            cell: (row) => 
            (
                <>
                    <Link to={"read/"+row.id}>
                        <Button className="action__btn" variant="info">
                            <GrView/>
                        </Button>
                    </Link>                  
                </>
            ),            
        }
    ]

    return (
        <>
        <Layout>
            <DataTable 
                title="Contacts Help"
                columns={columns} 
                data={filterJobPost} 
                progressPending={loading}
                pagination
                paginationServer
                paginationComponentOptions={{ noRowsPerPage: true }}
                paginationTotalRows={totalRows}
                // onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                fixedHeader
                fixedHeaderScrollHeight="800px"
                highlightOnHover
                // subHeader
                // subHeaderComponent={
                //     <input 
                //     type="text" 
                //     placeholder="Search Here" 
                //     className="search_btn form-control"
                //     value={search}
                //     onChange={(e)=> setSearch(e.target.value)}
                //     />
                // }
                subHeaderAlign="right"
            />
        </Layout>
        </>
    );
};

export default ContactsHelp;