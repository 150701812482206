import React, { useEffect, useState } from "react";
import { FaBars, FaExclamationCircle } from "react-icons/fa";
import {
  MdOutlineDashboardCustomize,
  MdVpnKey,
  MdAdminPanelSettings,
  MdSupervisedUserCircle,
  MdOutlinePerson,
  MdOutlineCategory,
  MdModelTraining,
} from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { GiSkills } from "react-icons/gi";
import { VscGroupByRefType } from "react-icons/vsc";
import { BsFillBagFill, BsFillBagCheckFill } from "react-icons/bs";
import { FiMap } from "react-icons/fi";
import { NavLink, Link } from "react-router-dom";
import user from "../../images/avater.svg";

import "./sidebar.css";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";

import DropdownMenu from "../DropdownMenu";

import http from "../../http";

const Sidebar = ({ children }) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  // const {  User, UpdatedUser } = useSelector(state=> state);
  // const dispatch = useDispatch()

  // console.table(cookies.get('userAuth'));
  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const removeUser = async () => {
    http
      .get("/api/admin/logout", {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((res) => {
        // console(res.data.message)
      });

    await cookies.remove("userAuthToken");
    navigate("/");
  };
  // const token = cookies.get('userAuth')?.token
  // useEffect(()=>{

  //     if(token){
  //         dispatch(fetchUsers());
  //     }
  // },[token]);

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const showSub = () => setShowMenu(!showMenu);
  //console.log(isOpen)

  useEffect(() => {
    if (window.innerWidth < 764) {
      setIsOpen(false);
    }
  }, [window.innerWidth]);

  const menuItem = [
    {
      id: 0,
      path: "/dashboard",
      name: "Dashboard",
      icon: <MdOutlineDashboardCustomize />,
      permissions: [],
      subMenu: [],
    },
    {
      id: 1,
      path: "/user-list",
      name: "Users",
      icon: <FaUserAlt />,
      permissions: [
        "appointment.getAll",
        "appointment.create",
        "appointment.update",
        "appointment.details",
        "appointment.delete",
      ],
      subMenu: [
        {
          path: "/admin",
          name: "Admin",
          icon: <MdAdminPanelSettings />,
        },
        {
          path: "/recruiters",
          name: "Company",
          icon: <MdOutlinePerson />,
        },
        {
          path: "/candidates",
          name: "Candidate",
          icon: <MdSupervisedUserCircle />,
        },
        {
          path: "/community-admin",
          name: "Community Admin",
          icon: <MdSupervisedUserCircle />,
        },
      ],
    },

    {
      id: 2,
      path: "/role-list",
      name: "Roles",
      icon: <MdVpnKey />,
      permissions: [
        "job.getAll",
        "job.create",
        "job.update",
        "job.details",
        "job.delete",
      ],
      subMenu: [],
    },
    {
      id: 3,
      path: "/job",
      name: "Job Mapping",
      icon: <BsFillBagFill />,
      permissions: [
        "job.getAll",
        "job.create",
        "job.update",
        "job.details",
        "job.delete",
      ],
      subMenu: [
        {
          path: "/job-category",
          name: "Category",
          icon: <MdOutlineCategory />,
        },
        // {
        //     path:"/job-designation",
        //     name:"Designation",
        //     icon:<BsFillBagCheckFill/>,
        // },

        {
          path: "/job-skills",
          name: "Skills",
          icon: <GiSkills />,
        },
        {
          path: "/job-designation",
          name: "Domain",
          icon: <GiSkills />,
        },
        {
          path: "/job-type",
          name: "Types",
          icon: <VscGroupByRefType />,
        },
        {
          path: "/work-model",
          name: "Work Model",
          icon: <MdModelTraining />,
        },
      ],
    },
    {
      id: 4,
      path: "/degree",
      name: "Degree",
      icon: <GiSkills />,
      permissions: [],
      subMenu: [],
    },
    {
      id: 4,
      path: "/community-type",
      name: "Community Type",
      icon: <GiSkills />,
      permissions: [],
      subMenu: [],
    },
    {
      id: 5,
      path: "/contacts-help",
      name: "Contacts Help",
      icon: <GiSkills />,
      permissions: [],
      subMenu: [],
    },

    // {
    //     path:"/countries",
    //     name:"Countries",
    //     icon:<FiMap/>,
    //     permissions: ["job.getAll","job.create","job.update","job.details","job.delete"],
    //     subMenu:[]
    // },
  ];
  // const image = process.env.REACT_APP_IMAGE_BASE_URL

  return (
    <>
      <div className="container">
        <div
          style={{ minWidth: isOpen ? "300px" : "50px" }}
          className={`sidebar ${isOpen ? "sidebar-open" : "sidebar-closed"}`}
        >
          <div className="top_section">
            {/* <img src={logo} className="logo" /> */}
            <div className="right_text">TalentsCube</div>
            <div
              style={{
                marginLeft: isOpen ? "40px" : "0px",
                width: isOpen ? "43px" : "20px",
              }}
              className="bars"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <DropdownMenu item={item} key={index} isOpen={isOpen} />
          ))}
        </div>
        <div className="menu_wrapper">
          <div
            className="top_navbar"
            style={{ marginLeft: isOpen ? "300px" : "50px" }}
          >
            {cookies.get["userAuthToken"] !== null && (
              <>
                {/* <span className="user_name">Hi {cookies.get('userAuth')?.name}! </span>  */}
                <div className="user_wraper">
                  <span className="user">
                    <img src={user} />
                    <span className="down_arrow"></span>

                    {/* <span className="user" ><img src={cookies.get('userAuth')?.image_url ? image+'/'+cookies.get('userAuth').image_url: avater}/> */}
                    <ul className="user_profile_list">
                      {/* 
                                           <Link to='/user/profile'><li className='up_item'>User Profile</li></Link>
                                          */}
                      <li
                        className="up_item"
                        type="button"
                        onClick={handleShow}
                      >
                        logout
                      </li>
                    </ul>
                  </span>
                </div>
              </>
            )}
          </div>
          <main
            className="mt-3"
            style={{ paddingLeft: isOpen ? "300px" : "50px" }}
          >
            <div className="ms-4 children_wrapper">{children}</div>
          </main>
        </div>
      </div>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "50px", margin: "auto" }}>
            <FaExclamationCircle />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center", fontSize: "50px" }}>
          Wanna out?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ backgroundColor: "#18B9AF" }} onClick={removeUser}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Sidebar;
