import React, { useEffect, useState } from "react";
import "./auth.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Form } from "react-bootstrap";
import http from "../../http";
import emailLogo from "../../images/email.svg";
import passLogo from "../../images/pass.svg";
import Cookies from "universal-cookie";
import loginCheck from "../../helpers/loginCheck";

const cookies = new Cookies();
const Login = () => {
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const [getError, setError] = useState({});
  const [getAuthError, setAuthError] = useState(false);
  const [getType, setType] = useState(false);

  useEffect(()=> {
    if(loginCheck()){
        navigate("/dashboard");
    }
});


  const onSubmit = (data, e) => {
    setError({});
    setAuthError(false);
    setType(false);
    setData(data);
    http
      .post("/api/admin/login", data)
      .then((res) => {
        if (Object.values(res.data.data.role)[0] === "Admin") {
          // console.log("check admin", Object.values(res.data.data.role)[0])
          setType(false);
          cookies.set("userAuthToken", JSON.stringify(res.data.data.apiToken));
          navigate("/dashboard");
        
        } else if (
          Object.values(res.data.data.role)[0] === "Recruiter" ||
          Object.values(res.data.data.role)[0] === "Candidate"
        ) {
          console.log("check other", Object.values(res.data.data.role)[0]);
          setType(true);
        }
      })
      .catch((err) => {
        if (err.response.data.message == "Validation error.") {
          let errs = err.response.data.data;
          let keys = Object.keys(errs);
          //console.log(keys);
          let errors = {};
          keys.map((key) => (errors[key] = errs[key][0]));
          //console.info(errors);
          setError(errors);
        } else {
          setAuthError(true);
        }
      });
  };
  //console.log(getAuthError)
  const onError = (errors, e) => console.log(errors, e);

  const handleChange = () => {
    setError("");
    setAuthError(false);
    setType(false);
  };

  return (
    <>
      <div className="form-design">
        <Form className="form-wrapper" onSubmit={handleSubmit(onSubmit)}>
          <h2 style={{ margin: "80px", marginBottom: "30px" }}>TalentsCube</h2>
          {getAuthError ? (
            <div className="mt-4">
              <p className="authError">The Credential is not correct</p>
            </div>
          ) : null}
          {/* {Object.keys(getError).length !== 0 ?
                        <div className="mt-4">
                            <p className="authError">
                                Check your credentials and try again
                            </p>
                        </div>
                        :
                        ''
                    } */}
          {getType ? (
            <div className="mt-4">
              <p className="authError">You are not allowed to login</p>
            </div>
          ) : null}
          <Form.Group className="input_wrapper">
            <Form.Control
              type="email"
              name="email"
              className="email"
              placeholder="Enter the Email"
              {...register("email")}
            />
            <div className="input_field_icon">
              <img alt="content_img" src={emailLogo} />
            </div>
            {getError.email ? (
              <span className="text-danger warn" style={{ marginTop: "2px" }}>
                {getError.email}
              </span>
            ) : null}
          </Form.Group>

          <Form.Group className="input_wrapper">
            <Form.Control
              type="password"
              name="password"
              className="password"
              // value={id}
              placeholder="Enter the password"
              {...register("password")}
            />
            <div className="input_field_icon">
              <img alt="content_img" src={passLogo} />
            </div>
            {getError.password ? (
              <span className="text-danger warn">{getError.password}</span>
            ) : null}
          </Form.Group>
          <div className="mt-4">
            <Button
              className="login_btn"
              style={{ backgroundColor: "#10827B" }}
              onClick={handleChange}
              type="submit"
            >
              LOGIN
            </Button>
            <Link to="/forgot-password" className="forgot-password">
              Forgot Password?
            </Link>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Login;
