import React, { useState, useEffect } from "react";
import "./community.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button, Badge } from "react-bootstrap";
import http from "../../http";
import TextLineLoader from "../../components/Loaders/TextLineLoader";
import { BsFillCursorFill } from "react-icons/bs";
import loginCheck from "../../helpers/loginCheck";
import Layout from "../../components/layouts/layout";
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FullEditor from "ckeditor5-build-full";
import { SelectPicker, DatePicker } from "rsuite";
import ComponentGuard from "../../helpers/ComponentGuard";
import Cookies from "universal-cookie";
const DomainRead = () => {
  const [domainView, setDomainView] = useState("");
  const { id } = useParams();
  const image = process.env.REACT_APP_IMAGE_BASE_URL

  const navigate = useNavigate();
  const cookies = new Cookies();
  useEffect(() => {
    if (!loginCheck()) {
      // console.log("this works");
      navigate("/");
    }
  });

  // console.log(jobView);
  useEffect(() => {
    fetchSingleDomainPost();
  }, []);

  const fetchSingleDomainPost = () => {
    http
      .get("/api/admin/contacts-help-list-get/" + id, {
        headers: {
          Authorization: "Bearer " + cookies.get("userAuthToken"),
        },
      })
      .then((res) => {
        setDomainView(res.data.data);
      });
  };

  return (
    <>
      <Layout>
        <div className="read">
          <div className="card-body">
            <div className="col-md-12">
              {domainView === "" ? (
                <>
                  <TextLineLoader />
                  <TextLineLoader />
                  <TextLineLoader />
                </>
              ) : (
                <div className="row">
                  <table>
                    <tr>
                        <th>Type</th>
                        <th>: </th>
                        <td>{domainView.subject_type}</td>
                    </tr>
                    <tr>
                        <th>Subject</th>
                        <th>: </th>
                        <td>{domainView.contact_subject.subject}</td>
                    </tr>
                    <tr>
                        <th>Description</th>
                        <th>:</th>
                        <td>{domainView.description}</td>
                    </tr>
                    <tr>
                        <th>Attachment</th>
                        <th>: </th>
                        <td>
                            {domainView.file ? (
                              <img width={150} height={150} src={image+'/'+domainView.file} /> 
                            ) : (
                              <Badge bg="warning" text="dark">N/A</Badge>
                            )}
                        </td>
                    </tr>
                    <tr>
                        <th>Status</th>
                        <th>: </th>
                        <td>
                            {domainView.status == "Pending"  ? (
                              <Badge bg="warning" text="dark">
                                {domainView.status}
                              </Badge>
                            ) : (
                              <Badge bg="info" text="light">
                                {domainView.status}
                              </Badge>
                            )}
                        </td>
                    </tr>
                  </table>
                </div>
              )}
            </div>
          </div>

          <div
            className="mt-4"
            style={{
              textAlign: "center",
              position: "absolute",
              right: 0,
              bottom: 0,
            }}
          >
            <Link to="/contacts-help">
              <Button className="action__btn" variant="info">
                Close
              </Button>
            </Link>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DomainRead;
